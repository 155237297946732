import AbstractApiClient from "../../themes/utils/AbstractApiClient";
import injectValidationErrors from "../utils/injectValidationErrors";
import { GuestMeetingAvailability, parseRawAvailabilities, formatAvailabilitiesToParams } from "./GuestMeetingAvailability";

export default class ApiClient extends AbstractApiClient {

  constructor(eventId: string, guestId: string, secret: string) {
    super(guestId, secret, `/api/v1/events/${eventId}/guests/${guestId}/meeting_availabilities`);
  }

  updateGuestMeetingAvailabilities(availabilities: GuestMeetingAvailability[], callback: (response: GuestMeetingAvailability[], success: boolean) => void): void {
    const params = {
      meeting_availabilities: formatAvailabilitiesToParams(availabilities)
    };

    this.sendRequest("PUT", params, null, (status, response) => {
      if (status === 200) {
        const availabilities = parseRawAvailabilities(response.meeting_availabilities);
        callback(availabilities, true);
        return;
      }

      callback(injectValidationErrors(availabilities, response), false);
    });
  }
}
