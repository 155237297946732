import { Component } from "react";
import CreatableSelect from "react-select/creatable";
import Minus from "../../icons/Minus.react";
import Plus from "../../icons/Plus.react";

interface Props {
  num?: number;
  min: number;
  max: number;
  step: number;
  disabled?: boolean;
  ariaLabel?: string;
  inModal?: boolean;

  format?: (n: number) => string;
  onChange: (n: number) => void;
}

export default class NumberPicker extends Component<Props> {
  constructor(props: Props) {
    super(props);

    [
      "inputIsValid",
      "onSelected"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  options(): any {
    const { min, max, step } = this.props;

    const options = [];

    for (let n = min; n <= max; n = n + step) {
      options.push({
        value: n, label: this.formatNumber(n)
      });
    }

    return options;
  }

  onSelected({ value }, { action }): void {
    const n = action === "create-option" ? parseInt(value) : value;

    this.props.onChange(n);
  }

  inputIsValid(value: string): boolean {
    const n = parseInt(value);
    if (isNaN(n)) return false;

    const { min, max } = this.props;

    return n >= min && n <= max;
  }

  selectStyles(): any {
    const { inModal } = this.props;

    if (inModal) {
      return {
        control: (base): any => ({
          ...base,
          borderRadius: "0px"
        })
      };
    } else {
      return {
        control: (base): any => ({
          ...base,
          borderRadius: "0px"
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        menuPortal: ({ left, ...provided }, state) => ({
          ...provided
        })
      };
    }
  }

  formatNumber(n: number): string {
    const { format } = this.props;

    return format ? format(n) : `${n}`;
  }

  renderInputAddon(icon: JSX.Element, onClick: () => void, globalyDisabled?: boolean, disabled?: boolean): JSX.Element {
    const isDisabled = this.props.disabled || disabled;
    let disabledClass = isDisabled ? "disabled" : "";
    disabledClass = globalyDisabled ? `${disabledClass} globaly-disabled` : disabledClass;
    const fn = isDisabled ? (): void => {} : onClick;

    return <span className={`input-group-addon ${disabledClass}`} onClick={fn}>
      {icon}
    </span>;
  }

  render(): JSX.Element {
    const { num, min, max, step, disabled, onChange, ariaLabel, inModal } = this.props;

    return <div className="input-group number-picker-wrapper">
      {this.renderInputAddon(<Minus />, () => {
        onChange(num - step);
      }, disabled, num - step < min)}

      <CreatableSelect
        value={{ value: num, label: this.formatNumber(num) }}
        options={this.options()}
        components={{ DropdownIndicator: (): JSX.Element => null, IndicatorSeparator: (): JSX.Element => null }}
        noOptionsMessage={(): string => null}
        formatCreateLabel={(value): string => this.formatNumber(value)}
        isValidNewOption={this.inputIsValid}
        onChange={this.onSelected}
        styles={this.selectStyles()}
        isDisabled={disabled}
        className="react-select"
        classNamePrefix="react-select"
        aria-label={ariaLabel}
        menuPlacement="auto"
        menuPosition={inModal ? "absolute" : "fixed"}
      />

      {this.renderInputAddon(<Plus />, () => {
        onChange(num + step);
      }, disabled, num + step > max)}
    </div>;
  }
}
