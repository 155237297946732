import { FunctionComponent, ChangeEvent } from "react";
import { NetworkingMeetingType } from "../../../constants/Constants";
import { GuestMeetingType } from "../../types/GuestMeetingAvailability";

interface Props {
  type: GuestMeetingType;
  defaultType: GuestMeetingType;
  disabled?: boolean;

  onChange: (type: string) => void;
}

const defineAvailabilityTypes = (type: GuestMeetingType): string[] => {
  const { VIRTUAL, PHYSICAL, PHYSICAL_OR_VIRTUAL } = NetworkingMeetingType;

  let availabilityTypes = [];
  if (type === PHYSICAL_OR_VIRTUAL) {
    availabilityTypes = [PHYSICAL_OR_VIRTUAL, VIRTUAL, PHYSICAL];
  } else {
    availabilityTypes = [type];
  }
  return availabilityTypes;
};

const AvailabilityTypePicker: FunctionComponent<Props> = ({ type, defaultType, disabled, onChange }) => {
  const { PHYSICAL_OR_VIRTUAL } = NetworkingMeetingType;
  const availabilityTypes = defineAvailabilityTypes(defaultType === PHYSICAL_OR_VIRTUAL ? defaultType : type);
  const availabilityTypeOptions = availabilityTypes.map(availabilityType => {
    return <option value={availabilityType} key={availabilityType}>{I18n.t(`guest_meeting_availabilities.day_row.availability_type.${availabilityType}`)}</option>;
  });

  return <select className="form-select form-control-availability" disabled={disabled} onChange={(e: ChangeEvent<HTMLSelectElement>): void => onChange(e.target.value)} value={type || defaultType}>
    {availabilityTypeOptions}
  </select>;
};

export default AvailabilityTypePicker;
