import { GuestMeetingAvailability } from "../types/GuestMeetingAvailability";

// Validation errors in an object that has meeting availabilities (i.e: event) are stored like this:
// meeting_availabilities[IDX] => array of errors (string) for this availability
export default function injectValidationErrors(availabilities: GuestMeetingAvailability[], hasAvailabilityErrors: any): GuestMeetingAvailability[] {
  if (!hasAvailabilityErrors) {
    // no more errors
    return availabilities.map(a => {
      return { ...a, errors: null };
    });
  }

  return availabilities.map((a, i) => {
    const errors = hasAvailabilityErrors[`meeting_availabilities[${i}]`];
    return { ...a, errors };
  });
}
