import { FunctionComponent } from "react";

const Peoples: FunctionComponent = (): JSX.Element => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5.99967" cy="4.66667" r="2.66667" stroke="#737373" strokeWidth="1.33333"/>
    <path d="M10.667 7.24929C11.8171 6.95327 12.667 5.9092 12.667 4.66664C12.667 3.42408 11.8171 2.38001 10.667 2.08398" stroke="#737373" strokeWidth="1.33333" strokeLinecap="round"/>
    <path d="M5.99967 9.33301C2.88856 9.33301 1.33301 9.95007 1.33301 12.2905C1.33301 14.631 10.6663 14.5069 10.6663 12.2905C10.6663 9.95007 9.11079 9.33301 5.99967 9.33301Z" stroke="#737373" strokeWidth="1.33333" strokeLinecap="round"/>
    <path d="M12.6663 13.7074C13.834 13.4286 14.6663 12.9533 14.6663 12.2914C14.6663 10.6365 13.8886 9.84322 12.333 9.52051" stroke="#737373" strokeWidth="1.33333" strokeLinecap="round"/>
  </svg>;
};

export default Peoples;
