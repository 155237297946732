import { FunctionComponent } from "react";

const Simultaneous: FunctionComponent = (): JSX.Element => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 11.3333L12 11.3333M12 11.3333L9.33333 8.66667M12 11.3333L9.33333 14" stroke="#737373" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 4.33333L12 4.33333M12 4.33333L9.33333 1.66667M12 4.33333L9.33333 7" stroke="#737373" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default Simultaneous;
