import { FunctionComponent } from "react";

const Plus: FunctionComponent = (): JSX.Element => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 4V7.33333C8 7.70152 7.70153 8 7.33334 8H4.00001" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 12V8.66667C8 8.29848 8.29847 8 8.66666 8H12" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
};

export default Plus;
