import moment, { Moment } from "moment";
import { TIME_STEP } from "../Constants";

type Time = {
  hour: number;
  minute: number;
}

export default Time;

const SEP = ":";

export function nextTime(time: Time, step = TIME_STEP): Time {
  if (time.minute < 60 - step) {
    return { ...time, minute: time.minute + step };
  }
  if (time.hour < 23) {
    return { hour: time.hour + 1, minute: 0 };
  }
  return null;
}

export function compareTime(t1: Time, t2: Time): 0|1|-1 {
  if (t1.hour > t2.hour) return 1;
  if (t1.hour < t2.hour) return -1;

  if (t1.minute > t2.minute) return 1;
  if (t1.minute < t2.minute) return -1;

  return 0;
}

export function addMinutes({ hour, minute }, minutesToAdd: number): Time {
  const base = moment().hour(hour).minute(minute);
  const date = base.add(minutesToAdd, "minutes");
  return momentToTime(date);
}

export function formatTime({ hour, minute }): string {
  const fmtOpts = { minimumIntegerDigits: 2 };
  return [
    hour.toLocaleString(undefined, fmtOpts),
    minute.toLocaleString(undefined, fmtOpts)
  ].join(SEP);
}

export function momentToTime(m: Moment): Time {
  return {
    hour: m.hour(),
    minute: m.minute()
  };
}

export function stringToTime(value: string): Time {
  if (!value) return null;

  // ensure format is hh:mm
  const components = value.split(SEP);
  if (components.length !== 2) return null;

  // ensure mm has 2 digits
  if (components[1].length !== 2) return null;

  // ensure hh and mm are integer
  const hour = parseInt(components[0]);
  const minute = parseInt(components[1]);
  if ([hour, minute].includes(NaN)) return null;

  return { hour, minute };
}
