import { Component } from "react";
import { Moment } from "moment";
import ChevronRight from "../../icons/ChevronRight.react";
import ChevronLeft from "../../icons/ChevronLeft.react";
import Calendar from "../../icons/Calendar.react";
import formatWeek from "../formatWeek";
import "./styles.scss";

interface Props {
  weeks: [Moment, Moment][][];
  weekNumber: number;

  onChange: (weekNumber: number) => void;
}

export default class WeekPicker extends Component<Props> {
  constructor(props: Props) {
    super(props);

    [
      "onChange"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  onChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    this.props.onChange(parseInt(e.target.value));
  }

  options(): JSX.Element[] {
    const { weeks } = this.props;
    return weeks.map((week, i) => {
      return <option key={`week_opt_${i}`} value={i}>{formatWeek(week)}</option>;
    });
  }

  renderInputAddon(icon: JSX.Element, onClick: () => void, classes: string, disabled?: boolean): JSX.Element {
    const disabledClass = disabled ? "disabled" : "";
    const fn = disabled ? (): void => {} : onClick;

    return <span className={`input-group-addon ${classes} ${disabledClass}`} onClick={fn}>
      {icon}
    </span>;
  }

  render(): JSX.Element {
    const { weeks, weekNumber, onChange } = this.props;

    if ((weeks || []).length === 0) return null;

    return <>
      <div className="input-group">
        <span className="input-group-addon calendar">
          <Calendar />
        </span>
        <select className="form-select" onChange={this.onChange} value={weekNumber}>
          {this.options()}
        </select>
        {this.renderInputAddon(<ChevronLeft />, () => {
          onChange(weekNumber - 1);
        }, "chevron-left", weekNumber === 0)}

        {this.renderInputAddon(<ChevronRight />, () => {
          onChange(weekNumber + 1);
        }, "chevron-right", weekNumber === weeks.length - 1)}
      </div>
    </>;
  }
}
