import { FunctionComponent } from "react";
import NumberPicker from "../utils/NumberPicker.react";

interface Props {
  capacity?: number;
  disabled?: boolean;
  simultaneousSlotsCount?: number;
  inModal?: boolean;

  onChange: (simultaneousSlotsCount: number) => void;
}

const MIN = 1;
const MAX = 20;
const STEP = 1;

const SimultaneousSlotsCountPicker: FunctionComponent<Props> = ({ simultaneousSlotsCount, disabled, inModal, onChange }) => {

  return <NumberPicker
    num={simultaneousSlotsCount}
    min={MIN}
    max={MAX}
    step={STEP}
    disabled={disabled}
    onChange={onChange}
    ariaLabel="Simultaneous slots capacity"
    inModal={inModal}
  />;
};

export default SimultaneousSlotsCountPicker;
