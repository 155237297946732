export type Credentials = {
  guestId: string;
  secret: string;
}

export default class AbstractApiClient {
  protected guestId: string;
  protected secret: string;
  protected baseEndpoint: string;
  public readonly credentials: Credentials;

  constructor(guestId: string, secret: string, baseEndpoint: string) {
    this.guestId = guestId;
    this.secret = secret;
    this.baseEndpoint = baseEndpoint;
    this.credentials = {
      guestId,
      secret
    };
  }

  protected sendRequest(method: "PUT"|"POST"|"GET"|"DELETE", params: any, path: string, callback: (status: number, response: any, nextLink?: string) => void): void {
    const xhr = new XMLHttpRequest();

    xhr.onload = (): void => {
      const response = xhr.responseText && xhr.responseText !== "" ? xhr.responseText : "{}";
      callback(xhr.status, JSON.parse(response), this.extractLink("next", xhr));
    };

    const qs = this.baseEndpoint.includes(this.guestId) ? (
      `secret=${this.secret}`
    ) : (
      `guest_id=${this.guestId}&secret=${this.secret}`
    );

    let endpoint = this.baseEndpoint;
    if (path && path.length > 0) endpoint = `${endpoint}/${path}`;
    endpoint = `${endpoint}.json?${qs}`;

    if (method === "GET") {
      const uriParams = new URLSearchParams(params).toString(); // turns params object into URI parameters since body cannot get passed to GET requests
      if (uriParams !== "") {
        endpoint += `&${uriParams}`;
      }
    }

    xhr.open(method, endpoint);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.send(JSON.stringify(params));
  }

  private extractLink(rel: string, xhr: XMLHttpRequest): string {
    const linkHeader = xhr.getResponseHeader("Link");
    if (!linkHeader) {
      return null;
    }
    const link = linkHeader.split(",").find(s => s.includes(`rel="${rel}"`));
    if (!link) {
      return null;
    }

    return link.split(";")[0].slice(1, -1);
  }
}
