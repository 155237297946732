import { FunctionComponent } from "react";
import TimePicker from "../utils/TimePicker.react";
import Time, { addMinutes } from "../../types/Time";

interface Props {
  minTime: Time;
  maxTime: Time;
  startTime: Time;
  endTime: Time;
  slotDurationMinutes: number;
  disabled?: boolean;
  inModal?: boolean;

  onChange: (key: "start"|"end", time: Time) => void;
}

const TimeRangePicker: FunctionComponent<Props> = ({ minTime, maxTime, slotDurationMinutes, startTime, endTime, disabled, inModal, onChange }) => {
  const onChangeHandler = (key: "start"|"end"): ((t: Time) => void) => {
    return (t: Time): void => {
      onChange(key, t);
    };
  };

  return <div className="time-range-picker-wrapper">
    <TimePicker
      minTime={minTime}
      maxTime={maxTime}
      lowerThanMax={true}
      time={startTime}
      onChange={onChangeHandler("start")}
      disabled={disabled}
      ariaLabel="Start time range"
      inModal={inModal}
    />
    <div className="separator"></div>
    <TimePicker
      minTime={addMinutes(startTime, slotDurationMinutes)}
      maxTime={maxTime}
      greaterThanMin={false}
      time={endTime}
      onChange={onChangeHandler("end")}
      disabled={disabled}
      ariaLabel="End time range"
      inModal={inModal}
    />
  </div>;
};

export default TimeRangePicker;
