import { Component } from "react";
import moment from "moment";
import Time, { momentToTime } from "../types/Time";
import { GuestMeetingAvailability, GuestMeetingType } from "../types/GuestMeetingAvailability";
import SlotDurationPicker from "./availability/SlotDurationPicker.react";
import CapacityPerSlotPicker from "./availability/CapacityPerSlotPicker.react";
import SimultaneousSlotsCountPicker from "./availability/SimultaneousSlotsCountPicker.react";
import TimeRangePicker from "./availability/TimeRangePicker.react";
import AvailabilityTypePicker from "./availability/AvailabilityTypePicker.react";
import Clock from "../icons/Clock.react";
import Peoples from "../icons/Peoples.react";
import Chrono from "../icons/Chrono.react";
import Simultaneous from "../icons/Simultaneous.react";

interface Props {
  maxTime: Time;
  minTime: Time;
  availability: GuestMeetingAvailability;
  slotDurationEditionDisabled?: boolean;
  slotCapacityEditionDisabled?: boolean;
  displayTypePicker: boolean;
  defaultType: GuestMeetingType;
  simultaneousSlotsEnabled: boolean;
  defaultSlotDurationMinutes: number;
  inModal?: boolean;

  updateAvailability: (availability: GuestMeetingAvailability) => void;
  destroyAvailabilities: (availabilities: GuestMeetingAvailability[]) => void;
}

export default class Availability extends Component<Props> {
  constructor(props: Props) {
    super(props);

    [
      "onTimeRangeChange",
      "destroyAvailability",
      "onSlotDurationChange",
      "onCapacityPerSlotChange",
      "onTypeChange",
      "onSimultaneousSlotsCountChange"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  onTimeRangeChange(key: "start"|"end", { hour, minute }): void {
    const { availability, updateAvailability } = this.props;

    const update = { ...availability };
    const field = `${key}Date`;
    update[field].minute(minute);
    update[field].hour(hour);

    updateAvailability(update);
  }

  onSlotDurationChange(slotDurationMinutes: number): void {
    const { availability, updateAvailability } = this.props;

    const update = { ...availability, slotDurationMinutes };
    updateAvailability(update);
  }

  onCapacityPerSlotChange(capacityPerSlot: number): void {
    const { availability, updateAvailability } = this.props;

    const update = { ...availability, capacityPerSlot };
    updateAvailability(update);
  }

  onSimultaneousSlotsCountChange(simultaneousSlotsCount: number): void {
    const { availability, updateAvailability } = this.props;

    const update = { ...availability, simultaneousSlotsCount };
    updateAvailability(update);
  }

  onTypeChange(type: string): void {
    const { availability, updateAvailability } = this.props;

    const update = { ...availability, type };
    updateAvailability(update);
  }

  destroyAvailability(e): void {
    e.preventDefault();
    const { availability, destroyAvailabilities } = this.props;
    destroyAvailabilities([availability]);
  }

  availabilityIsOver(): boolean {
    const { availability } = this.props;
    return moment().isAfter(availability.endDate);
  }

  renderDestroyButton(disabled?: boolean): JSX.Element {
    return <button className="btn btn-default square-button" onClick={(e): void => { this.destroyAvailability(e); }} disabled={disabled} aria-label={I18n.t("guest_meeting_availabilities.availability.remove")}>
      <i className="fa-regular fa-trash-can"></i>
      <span className="visible-xs ml-5">
        {I18n.t("guest_meeting_availabilities.availability.remove")}
      </span>
    </button>;
  }

  renderErrors(errors: string[]): JSX.Element {
    if (!errors || errors.length === 0) return null;

    return <ul className="availability-error text-small text-danger">
      {errors.map((err, i) => {
        return <li key={`error_${i}`}>{err}</li>;
      })}
    </ul>;
  }

  render(): JSX.Element {
    const { minTime, maxTime, availability, slotDurationEditionDisabled, slotCapacityEditionDisabled, displayTypePicker, defaultType, simultaneousSlotsEnabled, defaultSlotDurationMinutes, inModal } = this.props;
    const { startDate, endDate, slotDurationMinutes, capacityPerSlot, errors, type, simultaneousSlotsCount } = availability;
    const isOver = this.availabilityIsOver();

    return <div className="availability-wrapper">
      <div className="availability" style={{ "columnGap": "24px" }}>
        <div className="availability-item">
          <div className="visible-xs mobile-icon">
            <Clock />
          </div>
          <TimeRangePicker
            minTime={minTime}
            maxTime={maxTime}
            slotDurationMinutes={slotDurationMinutes}
            startTime={momentToTime(startDate)}
            endTime={momentToTime(endDate)}
            disabled={isOver}
            inModal={inModal}
            onChange={this.onTimeRangeChange}
          />
        </div>
        {!slotDurationEditionDisabled && (
          <div className="availability-item slot-duration-picker">
            <div className="visible-xs mobile-icon">
              <Chrono />
            </div>
            <SlotDurationPicker
              duration={slotDurationMinutes}
              onChange={this.onSlotDurationChange}
              disabled={isOver}
              defaultSlotDurationMinutes={defaultSlotDurationMinutes}
              inModal={inModal}
            />
          </div>
        )}
        {!slotCapacityEditionDisabled && (
          <div className="availability-item capacity-per-slot-picker">
            <div className="visible-xs mobile-icon">
              <Peoples />
            </div>
            <CapacityPerSlotPicker
              capacity={capacityPerSlot}
              onChange={this.onCapacityPerSlotChange}
              disabled={isOver}
              inModal={inModal}
            />
          </div>
        )}
        {displayTypePicker && (
          <div className="availability-item availability-type">
            <div className="visible-xs mobile-icon text-center">
              <i className="fa-regular fa-location-dot" style={{ color: "#737373" }}></i>
            </div>
            <AvailabilityTypePicker
              type={type}
              defaultType={defaultType}
              onChange={this.onTypeChange}
              disabled={isOver}
            />
          </div>
        )}
        {simultaneousSlotsEnabled && (
          <div className="availability-item capacity-per-slot-picker">
            <div className="visible-xs mobile-icon">
              <Simultaneous />
            </div>
            <SimultaneousSlotsCountPicker
              simultaneousSlotsCount={simultaneousSlotsCount}
              onChange={this.onSimultaneousSlotsCountChange}
              disabled={isOver}
              inModal={inModal}
            />
          </div>
        )}
        <div className="availability-item destroy-item">
          {this.renderDestroyButton(isOver)}
        </div>
      </div>
      {this.renderErrors(errors)}
    </div>;
  }
}
