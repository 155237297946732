import moment, { Moment } from "moment";

/*
This function builds a week calendar betwen 2 dates. It returns something like:
[
  [ # week 1
    [
      day start date,
      day end date
    ],
    [
      day start date,
      day end date
    ]
    ...
  ],
  [ # week 2

  ],
  ...
]

Hours and minutes of the start and end of a day are based on the hours and minutes of the initial
dates passed as arguments:
- if the fromDate passed as argument start at 09:30, all the days will start at 09:30.
- if the endDate passed as argument end at 20:30, all the days will end at 20:30.
- if scopeStartAndEndTime == false, all the days will start at 00:00 and end at 23:45.
*/


export default function buildWeeksCalendar(fromDate: string, toDate: string, scopeStartAndEndTime = true): [Moment, Moment][][] {
  const from = moment(fromDate);
  const to = moment(toDate);

  let daysStartHour = scopeStartAndEndTime ? from.hour() : 0;
  let daysEndHour = scopeStartAndEndTime ? to.hour() : 23;
  let daysStartMinutes = scopeStartAndEndTime ? from.minutes() : 0;
  let daysEndMinutes = scopeStartAndEndTime ? to.minutes() : 45;

  if (daysStartHour >= daysEndHour) {
    daysStartHour = 8;
    daysEndHour = 18;
    daysStartMinutes = 0;
    daysEndMinutes = 0;
  }
  const results = [];

  iterateWeeks(from, to, (start, end) => {
    const weekDays = [];

    const daysFrom = moment(start);
    let daysTo = moment(end);
    daysTo = daysTo.isAfter(to) ? to : daysTo;

    iterateDays(daysFrom, daysTo, (day) => {
      const dayStart = moment(day).set({ h: daysStartHour, m: daysStartMinutes });
      const dayEnd = moment(day).set({ h: daysEndHour, m: daysEndMinutes });
      weekDays.push([dayStart, dayEnd]);
    });

    results.push(weekDays);
  });

  return results;
}

function iterateWeeks(from: Moment, to: Moment, callback: (startDate: Moment, endDate: Moment) => void): void {
  let iterator = moment(from);

  while (iterator) {
    callback(moment(iterator), iterator.endOf("week"));

    const nextWeek = iterator.add(1, "week").startOf("week");
    iterator = nextWeek.isBefore(to) ? nextWeek : null;
  }
}

function iterateDays(from: Moment, to: Moment, callback: (date: Moment) => void): void {
  for (let iterator = moment(from); iterator.isBefore(to); iterator.add(1, "day")) {
    callback(moment(iterator));
  }
}
