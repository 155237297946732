import { Component } from "react";
import moment, { Moment } from "moment";
import WeekView from "./components/WeekView.react";
import buildWeeksCalendar from "../themes/utils/buildWeeksCalendar";
import ApiClient from "./types/ApiClient";
import {
  GuestMeetingAvailability,
  GuestMeetingAvailabilitiesProps,
  availabilitiesBetweenDays,
  sortAndIndexAvailabilities,
  parseRawAvailabilities,
  GuestMeetingType
} from "./types/GuestMeetingAvailability";
import WeekPicker from "../themes/utils/WeekPicker/WeekPicker.react";
import Loader from "../components/shared/Loader.react";
import injectValidationErrors from "./utils/injectValidationErrors";
import formatWeek from "../themes/utils/formatWeek";

interface Props extends GuestMeetingAvailabilitiesProps {
  // only needed when component is used on the theme
  guestId?: string;
  eventId?: string;
  secret?: string;
  inModal?: boolean;

  fromDate: string;
  toDate: string;
  availabilitiesStartTime?: string;
  availabilitiesEndTime?: string;
  defaultSlotDurationMinutes?: string;
  slotDurationEditionDisabled?: boolean;
  defaultSlotCapacity?: string;
  slotCapacityEditionDisabled?: boolean;
  hasMeetingAvailabilityErrors?: any;
  timeZoneIndication?: string;
  displayTypePicker: boolean;
  defaultType: GuestMeetingType;
  simultaneousSlotsEnabled: boolean;
}

interface State {
  weekNumber: number;
  availabilities: GuestMeetingAvailability[];
  hasUnsavedChanges: boolean;
  saving: boolean;
}

export default class Calendar extends Component<Props, State> {
  private weeks: [Moment, Moment][][];
  private apiClient: ApiClient;
  private usedInBackOffice: boolean;

  constructor(props: Props) {
    super(props);

    this.usedInBackOffice = !!this.props.onMeetingAvailabilitiesChanged;

    if (!this.usedInBackOffice) {
      const { eventId, guestId, secret } = props;

      this.apiClient = new ApiClient(eventId, guestId, secret);
    }

    this.weeks = buildWeeksCalendar(props.fromDate, props.toDate, !this.usedInBackOffice);

    this.state = {
      weekNumber: this.initWeekNumber(),
      availabilities: sortAndIndexAvailabilities(parseRawAvailabilities(props.guestMeetingAvailabilities)),
      hasUnsavedChanges: false,
      saving: false
    };

    [
      "createAvailability",
      "updateAvailability",
      "destroyAvailabilities",
      "onWeekChange",
      "saveAvailabilities"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.usedInBackOffice) {
      if (prevState.availabilities !== this.state.availabilities) {
        // warn the parent component availabilities have changed
        this.props.onMeetingAvailabilitiesChanged(this.state.availabilities);
      }

      // in case of the parents props changing, sync them into the component state !
      this.syncStateWithProps(prevProps);
    }
  }

  // When used from the backoffice, parent container may change some props. So we need to sync them
  // into the state if they changed
  syncStateWithProps(prevProps: Props): void {
    const { defaultType, hasMeetingAvailabilityErrors } = this.props;

    let { availabilities: nextAvailabilities } = this.state; // by default, state do not change

    if (prevProps.defaultType !== defaultType) {
      // default type has changed, change it on all availabilities
      nextAvailabilities = nextAvailabilities.map(availability => {
        return { ...availability, type: defaultType };
      });
    }

    if (prevProps.hasMeetingAvailabilityErrors !== hasMeetingAvailabilityErrors) {
      // some validation errors may have showed up, inject them in availabilities
      nextAvailabilities = injectValidationErrors(nextAvailabilities, hasMeetingAvailabilityErrors);
    }

    if (nextAvailabilities !== this.state.availabilities) {
      this.setState({
        availabilities: nextAvailabilities
      });
    }
  }

  /* ensure that calendar displays right week tab depending on current date
  - before the event -> we display first week tab
  - after the event -> we display last week tab
  - during the event -> we display current week tab
  */
  initWeekNumber(): number {
    if (!this.eventOnDifferentWeeks()) {
      return 0;
    }

    const { fromDate, toDate } = this.props;
    const today = moment();
    const startOfFirstWeek = moment(fromDate).startOf("week");
    const endOfLastWeek = moment(toDate).endOf("week");

    if (today < startOfFirstWeek) {
      return 0;
    } else if (today > endOfLastWeek) {
      return this.weeks.length - 1;
    }

    return today.diff(startOfFirstWeek, "week");
  }

  saveAvailabilities(): void {
    this.setState({
      saving: true
    });

    this.apiClient.updateGuestMeetingAvailabilities(this.state.availabilities, (availabilities, success) => {
      this.setState({
        hasUnsavedChanges: !success,
        saving: false,
        availabilities: sortAndIndexAvailabilities(availabilities)
      });

      const { onMeetingAvailabilitiesSaved } = this.props;
      onMeetingAvailabilitiesSaved && onMeetingAvailabilitiesSaved(availabilities);
    });
  }

  createAvailability(availability: GuestMeetingAvailability): void {
    const nextAvailabilities = [...this.state.availabilities, availability];
    this.setState({
      hasUnsavedChanges: true,
      availabilities: sortAndIndexAvailabilities(nextAvailabilities)
    });
  }

  updateAvailability(availability: GuestMeetingAvailability): void {
    const nextAvailabilities = Object.assign([], this.state.availabilities, { [availability.index]: availability });
    this.setState({
      hasUnsavedChanges: true,
      availabilities: sortAndIndexAvailabilities(nextAvailabilities)
    });
  }

  destroyAvailabilities(availabilities: GuestMeetingAvailability[]): void {
    const indexes = availabilities.map(a => a.index);
    const nextAvailabilities = this.state.availabilities.filter(a => !indexes.includes(a.index));
    this.setState({
      hasUnsavedChanges: true,
      availabilities: sortAndIndexAvailabilities(nextAvailabilities)
    });
  }

  onWeekChange(weekNumber: number): void {
    this.setState({
      weekNumber
    });
  }

  weekDateRange(weekNumber = this.state.weekNumber): [Moment, Moment] {
    const week = this.weeks[weekNumber];
    const [firstDayStart, ] = week[0];
    const [, lastDayEnd] = week[week.length - 1];

    return [firstDayStart, lastDayEnd];
  }

  currentWeekAvailabilities(): GuestMeetingAvailability[] {
    const { availabilities } = this.state;
    const [weekStart, weekEnd] = this.weekDateRange();
    return availabilitiesBetweenDays(availabilities, weekStart, weekEnd);
  }

  i18n(key: string, opts = {}): string {
    return I18n.t(`guest_meeting_availabilities.calendar.${key}`, opts);
  }

  renderWeekView(): JSX.Element {
    const { weekNumber } = this.state;
    const days = this.weeks[weekNumber];
    const {
      availabilitiesStartTime,
      availabilitiesEndTime,
      defaultSlotDurationMinutes,
      defaultSlotCapacity,
      slotDurationEditionDisabled,
      slotCapacityEditionDisabled,
      timeZoneIndication,
      displayTypePicker,
      defaultType,
      simultaneousSlotsEnabled,
      inModal
    } = this.props;

    return <WeekView
      days={days}
      availabilities={this.currentWeekAvailabilities()}
      createAvailability={this.createAvailability}
      updateAvailability={this.updateAvailability}
      destroyAvailabilities={this.destroyAvailabilities}
      availabilitiesStartTime={availabilitiesStartTime}
      availabilitiesEndTime={availabilitiesEndTime}
      defaultSlotDurationMinutes={defaultSlotDurationMinutes}
      defaultSlotCapacity={defaultSlotCapacity}
      slotDurationEditionDisabled={slotDurationEditionDisabled}
      slotCapacityEditionDisabled={slotCapacityEditionDisabled}
      timeZoneIndication={timeZoneIndication}
      displayTypePicker={displayTypePicker}
      defaultType={defaultType}
      simultaneousSlotsEnabled={simultaneousSlotsEnabled}
      inModal={inModal}
    />;
  }

  eventOnDifferentWeeks(): boolean {
    const { fromDate, toDate } = this.props;
    const from = moment(fromDate);
    const to = moment(toDate);

    return from.weeks() !== to.weeks();
  }

  renderContent(): JSX.Element {
    const { availabilities } = this.state;

    if (!availabilities) {
      return <Loader size="large" inline={false} message={I18n.t("react.loader.loading")}/>;
    }

    return this.renderWeekView();
  }

  renderWeekPicker(): JSX.Element {
    if (!this.eventOnDifferentWeeks()) return null;

    const { weekNumber } = this.state;

    return <WeekPicker
      weeks={this.weeks}
      weekNumber={weekNumber}
      onChange={this.onWeekChange}
    />;
  }

  renderWeeksValidationErrors(): JSX.Element {
    const { availabilities } = this.state;

    const weeksWithErrors = [];
    this.weeks.forEach((_week, i) => {
      const [start, end] = this.weekDateRange(i);
      const weekAvailabilities = availabilitiesBetweenDays(availabilities, start, end);
      const hasError = weekAvailabilities.find(a => a.errors);
      if (hasError) {
        weeksWithErrors.push(i);
      }
    });

    if (weeksWithErrors.length === 0) {
      return null;
    }

    return (
      <div className="alert alert-danger">
        <ul>
          {weeksWithErrors.map(i => {
            const linkToWeek = <a
              href="#"
              onClick={(e): void => {
                e.preventDefault();
                this.onWeekChange(i);
              }}>
              {this.i18n("fix_week_validation_error")}
            </a>;

            return <li key={`week-error-${i}`}>
              {this.i18n("week_has_validation_error", { week: formatWeek(this.weeks[i]) })}, {linkToWeek}
            </li>;
          })}
        </ul>
      </div>
    );
  }

  saveButtonContent(): JSX.Element|string {
    const { hasUnsavedChanges, saving } = this.state;
    if (saving) {
      return <><Loader size="small" color="white"/>{"\u00A0\u00A0"}</>;
    }

    return hasUnsavedChanges ? this.i18n("save") : this.i18n("everything_saved");
  }

  renderSaveButton(): JSX.Element {
    if (this.usedInBackOffice) return null;

    const { hasUnsavedChanges, saving } = this.state;
    const disabled = !hasUnsavedChanges || saving;
    return <div className="display-flex fd-row jc-end">
      <button className="btn btn-primary" onClick={this.saveAvailabilities} disabled={disabled}>
        {this.saveButtonContent()}
      </button>
    </div>;
  }

  render(): JSX.Element {
    return <div className="guest-meeting-availabilities-wrapper">
      <div className="week-picker-wrapper mb-5">
        {this.renderWeekPicker()}
        {this.renderWeeksValidationErrors()}
      </div>
      {this.renderContent()}
      {this.renderSaveButton()}
    </div>;
  }
}
