import { FunctionComponent } from "react";
import NumberPicker from "../utils/NumberPicker.react";

interface Props {
  capacity?: number;
  disabled?: boolean;
  inModal?: boolean;

  onChange: (capacity: number) => void;
}

const MIN = 1;
const MAX = 25;
const STEP = 1;

const CapacityPerSlotPicker: FunctionComponent<Props> = ({ capacity, disabled, inModal, onChange }) => {

  return <NumberPicker
    num={capacity}
    min={MIN}
    max={MAX}
    step={STEP}
    disabled={disabled}
    onChange={onChange}
    ariaLabel="Capacity per slot"
    inModal={inModal}
  />;
};

export default CapacityPerSlotPicker;
