import { FunctionComponent } from "react";

const ChevronRight: FunctionComponent = (): JSX.Element => {
  return <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M6.49984 3.33301L11.1665 7.99967L6.49984 12.6663" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>;
};

export default ChevronRight;
