import moment, { Moment } from "moment";
import { NetworkingMeetingType } from "../../constants/Constants";
import momentIgnoreTimezone from "../../themes/utils/momentIgnoreTimezone";

export const DEFAULT_SIMULTANEOUS_SLOTS_COUNT = 1;
export const DEFAULT_SLOT_CAPACITY = 1;

export type GuestMeetingType = NetworkingMeetingType.VIRTUAL | NetworkingMeetingType.PHYSICAL | NetworkingMeetingType.PHYSICAL_OR_VIRTUAL;

export type GuestMeetingAvailability = {
  startDate: Moment;
  endDate: Moment;
  slotDurationMinutes: number;
  capacityPerSlot: number; // this field will change to invitationCapacity
  type: GuestMeetingType;
  index?: number; // this field is only used internally by the component
  errors?: string[];
  simultaneousSlotsCount: number;
}

export interface GuestMeetingAvailabilitiesProps {
  guestMeetingAvailabilities: any[];

  onMeetingAvailabilitiesChanged?: (availabilities: any[]) => void;
  onMeetingAvailabilitiesSaved?: (availabilities: any[]) => void;
}

export function parseRawAvailabilities(data: any[]): GuestMeetingAvailability[] {
  return data.map(a => {
    return {
      startDate: a.startDate || momentIgnoreTimezone(a["start_date_in_timezone"]),
      endDate: a.endDate || momentIgnoreTimezone(a["end_date_in_timezone"]),
      slotDurationMinutes: a.slotDurationMinutes || a["slot_duration_minutes"],
      capacityPerSlot: -1,
      type: a["type"] || NetworkingMeetingType.PHYSICAL_OR_VIRTUAL,
      errors: null,
      simultaneousSlotsCount: a.simultaneousSlotsCount || a["simultaneous_slots_count"] || DEFAULT_SIMULTANEOUS_SLOTS_COUNT
    };
  });
}

export function formatAvailabilitiesToParams(availabilities: GuestMeetingAvailability[]): any[] {
  return availabilities.map(a => {
    return {
      local_start_date: dateToParam(a.startDate),
      local_end_date: dateToParam(a.endDate),
      slot_duration_minutes: a.slotDurationMinutes,
      type: a.type,
      simultaneous_slots_count: a.simultaneousSlotsCount
    };
  });
}

// this is really important. When sending date to the server, we don't want
// the user browser timezone to mess it up. So for example, if the user set a start
// date to 10:00 and his timezone is +1, we want to send 10:00 to the server and not
// 09:00
function dateToParam(date: Moment|string): string {
  return moment(date).utcOffset(0, true).format();
}

export function availabilitiesBetweenDays(availabilities: GuestMeetingAvailability[], start: Moment, end: Moment): GuestMeetingAvailability[] {
  return availabilities.filter(a => {
    const { startDate, endDate } = a;
    const startOfDay = moment(start).startOf("day");
    const endOfDay = moment(end).endOf("day");
    return moment(startDate).isSameOrAfter(startOfDay) && moment(endDate).isSameOrBefore(endOfDay);
  });
}

export function sortAndIndexAvailabilities(availabilities: GuestMeetingAvailability[]): GuestMeetingAvailability[] {
  return availabilities.sort((a, b) => {
    if (a.startDate.isBefore(b.startDate)) {
      return -1;
    }
    if (a.startDate.isAfter(b.startDate)) {
      return 1;
    }
    return 0;
  }).map((a, i) => { return { ...a, index: i }; });
}
