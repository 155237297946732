import { Component } from "react";
import moment, { Moment } from "moment";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Availability from "./Availability.react";
import { GuestMeetingAvailability, GuestMeetingType } from "../types/GuestMeetingAvailability";
import Time from "../types/Time";
import { roundMomentToNextStep } from "../utils/momentUtils";

interface Props {
  dayStart: Moment;
  dayEnd: Moment;
  availabilities: GuestMeetingAvailability[];
  defaultSlotDurationMinutes: number;
  defaultSlotCapacity: number;
  slotDurationEditionDisabled?: boolean;
  slotCapacityEditionDisabled?: boolean;
  displayTypePicker: boolean;
  defaultType: GuestMeetingType;
  simultaneousSlotsEnabled: boolean;
  defaultSimultaneousSlotsCount: number;
  inModal?: boolean;

  createAvailability: (params: GuestMeetingAvailability) => void;
  updateAvailability: (a: GuestMeetingAvailability) => void;
  destroyAvailabilities: (availabilities: GuestMeetingAvailability[]) => void;
}

export default class DayRow extends Component<Props> {
  constructor(props: Props) {
    super(props);

    [
      "onCheckboxChange",
      "addAvailability"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  addAvailability(e): void {
    e.preventDefault();
    const { availabilities, dayEnd, defaultSlotDurationMinutes, defaultSlotCapacity, defaultType, createAvailability, defaultSimultaneousSlotsCount } = this.props;
    if (availabilities.length === 0) {
      this.createDefaultAvailability();
      return;
    }

    createAvailability({
      capacityPerSlot: defaultSlotCapacity,
      startDate: this.dateOrNextAvailable(this.lastAvailability().endDate),
      endDate: moment(dayEnd),
      slotDurationMinutes: defaultSlotDurationMinutes,
      type: defaultType,
      simultaneousSlotsCount: defaultSimultaneousSlotsCount
    });
  }

  lastAvailability(): GuestMeetingAvailability {
    const { availabilities } = this.props;
    return availabilities[availabilities.length - 1];
  }

  onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.checked) {
      this.createDefaultAvailability();
    } else {
      this.destroyAllAvailabilities();
    }
  }

  dateOrNextAvailable(date: Moment): Moment {
    return moment().isBefore(date) ? moment(date) : roundMomentToNextStep(moment());
  }

  createDefaultAvailability(): void {
    const { dayStart, dayEnd, defaultSlotDurationMinutes, defaultSlotCapacity, defaultType, createAvailability, defaultSimultaneousSlotsCount } = this.props;
    createAvailability({
      capacityPerSlot: defaultSlotCapacity,
      startDate: this.dateOrNextAvailable(dayStart),
      endDate: moment(dayEnd),
      slotDurationMinutes: defaultSlotDurationMinutes,
      type: defaultType,
      simultaneousSlotsCount: defaultSimultaneousSlotsCount
    });
  }

  destroyAllAvailabilities(): void {
    const { availabilities, destroyAvailabilities } = this.props;
    destroyAvailabilities(availabilities);
  }

  renderCheckbox(): JSX.Element {
    const { dayStart, availabilities } = this.props;
    const checked = availabilities.length > 0;
    const id = `available-checkbox-${dayStart}`;

    return <div className="date-wrapper">
      <input id={id} type="checkbox" checked={checked} disabled={this.dayIsOver()} onChange={this.onCheckboxChange}/>
      <label htmlFor={id} className="form-label">{dayStart.format("ddd D").toUpperCase()}</label>
    </div>;
  }

  minTimeForAvailabilityAtIndex(i: number): Time {
    const { dayStart, availabilities } = this.props;

    const base = i === 0 ? dayStart : availabilities[i - 1].endDate;
    const realBase = this.dateOrNextAvailable(base);
    return { hour: realBase.hour(), minute: realBase.minute() };
  }

  maxTimeForAvailabilityAtIndex(i: number): Time {
    const { dayEnd, availabilities } = this.props;

    const base = i === availabilities.length - 1 ? dayEnd : availabilities[i + 1].startDate;
    return { hour: base.hour(), minute: base.minute() };
  }

  i18n(key: string): string {
    return I18n.t(`guest_meeting_availabilities.day_row.${key}`);
  }

  renderAvailabilities(): JSX.Element {
    const {
      availabilities,
      slotDurationEditionDisabled,
      slotCapacityEditionDisabled,
      displayTypePicker,
      defaultType,
      updateAvailability,
      destroyAvailabilities,
      simultaneousSlotsEnabled,
      defaultSlotDurationMinutes,
      inModal
    } = this.props;

    return <>
      {availabilities.map((availability, i) => {
        return <Availability
          key={availability.index}
          minTime={this.minTimeForAvailabilityAtIndex(i)}
          maxTime={this.maxTimeForAvailabilityAtIndex(i)}
          availability={availability}
          updateAvailability={updateAvailability}
          destroyAvailabilities={destroyAvailabilities}
          slotDurationEditionDisabled={slotDurationEditionDisabled}
          slotCapacityEditionDisabled={slotCapacityEditionDisabled}
          displayTypePicker={displayTypePicker}
          defaultType={defaultType}
          simultaneousSlotsEnabled={simultaneousSlotsEnabled}
          defaultSlotDurationMinutes={defaultSlotDurationMinutes}
          inModal={inModal}
        />;
      })}
      { this.renderAction() }
    </>;
  }

  renderNoAvailability(): JSX.Element {
    const disabled = !this.canAddAvailability();

    if (disabled) {
      return <span key="unavailable" className="text-muted">{this.i18n("unavailable")}</span>;
    }

    return <div>
      <span key="unavailable" className="text-muted">{this.i18n("no_availability")}</span> <a href="#" onClick={this.addAvailability}>{this.i18n("add_availability")}</a>
    </div>;
  }

  dayIsOver(): boolean {
    const { dayEnd } = this.props;

    return moment().isAfter(dayEnd);
  }

  canAddAvailability(): boolean {
    const { availabilities, dayEnd } = this.props;

    if (this.dayIsOver()) {
      return false;
    }

    return (
      availabilities.length === 0 ||
      this.lastAvailability().endDate.isBefore(dayEnd)
    );
  }

  renderAction(): JSX.Element {
    const disabled = !this.canAddAvailability();
    const button = <button className="btn btn-link add-availability" onClick={this.addAvailability} disabled={disabled} aria-label={this.i18n("add_availability")}>
      {this.i18n("add_availability")}
    </button>;

    if (disabled) return;

    return <OverlayTrigger placement="bottom" overlay={<Tooltip id={`add-time-range-help-${this.props.dayStart}`}>{this.i18n("add_range_help")}</Tooltip>}>
      <span className="add-availability-btn-wrapper">{button}</span>
    </OverlayTrigger>;
  }

  render(): JSX.Element {
    const { availabilities } = this.props;

    return <tr className="day-row-wrapper">
      <td className="day-column">
        {this.renderCheckbox()}
      </td>
      {availabilities.length === 0 ? (
        <td className="time-range-unavailable-column">
          {this.renderNoAvailability()}
        </td>
      ) : (
        <td className="time-range-available-column">
          {this.renderAvailabilities()}
        </td>
      )}
    </tr>;
  }
}
