import { Component } from "react";
import moment, { Moment } from "moment";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import DayRow from "./DayRow.react";
import { GuestMeetingAvailability,
  availabilitiesBetweenDays,
  GuestMeetingType,
  DEFAULT_SIMULTANEOUS_SLOTS_COUNT,
  DEFAULT_SLOT_CAPACITY
} from "../types/GuestMeetingAvailability";
import { stringToTime } from "../types/Time";

interface Props {
  days: [Moment, Moment][];
  availabilities: GuestMeetingAvailability[];
  availabilitiesStartTime?: string;
  availabilitiesEndTime?: string;
  defaultSlotDurationMinutes?: string;
  slotDurationEditionDisabled?: boolean;
  defaultSlotCapacity?: string;
  slotCapacityEditionDisabled?: boolean;
  timeZoneIndication?: string;
  displayTypePicker: boolean;
  defaultType: GuestMeetingType;
  simultaneousSlotsEnabled: boolean;
  inModal?: boolean;

  createAvailability: (params: GuestMeetingAvailability) => void;
  updateAvailability: (availability: GuestMeetingAvailability) => void;
  destroyAvailabilities: (availabilities: GuestMeetingAvailability[]) => void;
}

export default class WeekView extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  dayAvailabilities(dayIndex: number): GuestMeetingAvailability[] {
    const { days, availabilities } = this.props;
    const [dayStart, dayEnd] = days[dayIndex];

    return availabilitiesBetweenDays(availabilities, dayStart, dayEnd);
  }

  i18n(key: string): string {
    return I18n.t(`guest_meeting_availabilities.week_view.${key}`);
  }

  momentWithTime(m: Moment, { hour, minute }): Moment {
    return moment(m).hour(hour).minute(minute);
  }

  availabilitiesCanStartAt(dayStart: Moment): Moment {
    const time = stringToTime(this.props.availabilitiesStartTime);

    return time ? this.momentWithTime(dayStart, time) : dayStart;
  }

  availabilitiesCanEndAt(dayEnd: Moment): Moment {
    const time = stringToTime(this.props.availabilitiesEndTime);

    return time ? this.momentWithTime(dayEnd, time) : dayEnd;
  }

  intPropsOrDefault(key: string, defaultValue: number): number {
    const value = this.props[key];
    if (!value) return defaultValue;

    const result = parseInt(value);
    return isNaN(result) ? defaultValue : result;
  }

  defaultSlotCapacity(): number {
    return this.intPropsOrDefault("defaultSlotCapacity", DEFAULT_SLOT_CAPACITY);
  }

  defaultSimultaneousSlotsCount(): number {
    return this.intPropsOrDefault("defaultSimultaneousSlotsCount", DEFAULT_SIMULTANEOUS_SLOTS_COUNT);
  }

  renderHelpOverlay(help: string, id: string): JSX.Element {
    return <OverlayTrigger placement="top" overlay={<Tooltip id={id} bsPrefix="tooltips-meetings tooltip">{help}</Tooltip>}>
      <i className="fa-regular fa-info-circle"></i>
    </OverlayTrigger>;
  }

  renderTimezoneIndication(): JSX.Element {
    const { timeZoneIndication } = this.props;

    if (!timeZoneIndication) return;

    return <p className="text-muted" style={{ fontSize: "9px", fontWeight: "normal" }}>{ timeZoneIndication }</p>;
  }

  render(): JSX.Element {
    const {
      days,
      createAvailability,
      updateAvailability,
      destroyAvailabilities,
      slotDurationEditionDisabled,
      slotCapacityEditionDisabled,
      displayTypePicker,
      defaultType,
      simultaneousSlotsEnabled,
      defaultSlotDurationMinutes,
      inModal
    } = this.props;

    return <div className="panel panel-default week-view-wrapper table-container table-responsive">
      <table>
        <thead className="panel-heading hidden-xs">
          <tr style={{ "display": "flex", "columnGap": "24px" }}>
            <th className="header-title day">
              {this.i18n("day")}
            </th>
            <th className="header-title time-range">
              {this.i18n("time_range")} {this.renderTimezoneIndication()}
            </th>
            {!slotDurationEditionDisabled && (
              <th className="header-title slot-duration">
                {this.i18n("slot_duration")} {this.renderHelpOverlay(this.i18n("slot_duration_help"), "help-slot-duration")}
              </th>
            )}
            {!slotCapacityEditionDisabled && (
              <th className="header-title slot-capacity">
                {this.i18n("slot_capacity")} {this.renderHelpOverlay(this.i18n("slot_capacity_help"), "help-slot-capacity")}
              </th>
            )}
            {displayTypePicker && (
              <th className="header-title type">
                {this.i18n("type")}
              </th>
            )}
            {simultaneousSlotsEnabled && (
              <th className="header-title simultaneous-slots-capacity">
                {this.i18n("simultaneous_slots_count")} {this.renderHelpOverlay(this.i18n("simultaneous_slots_count_help"), "help-simultaneous-slots")}
              </th>
            )}
          </tr>
        </thead>
        <tbody className="panel-body">
          {days.map(([start, end], i) => {
            return <DayRow
              dayStart={this.availabilitiesCanStartAt(start)}
              dayEnd={this.availabilitiesCanEndAt(end)}
              availabilities={this.dayAvailabilities(i)}
              createAvailability={createAvailability}
              updateAvailability={updateAvailability}
              destroyAvailabilities={destroyAvailabilities}
              defaultSlotDurationMinutes={parseInt(defaultSlotDurationMinutes)}
              defaultSlotCapacity={this.defaultSlotCapacity()}
              defaultSimultaneousSlotsCount={this.defaultSimultaneousSlotsCount()}
              slotDurationEditionDisabled={slotDurationEditionDisabled}
              slotCapacityEditionDisabled={slotCapacityEditionDisabled}
              displayTypePicker={displayTypePicker}
              defaultType={defaultType}
              key={`day_${i}`}
              simultaneousSlotsEnabled={simultaneousSlotsEnabled}
              inModal={inModal}
            />;
          })}
        </tbody>
      </table>
    </div>;
  }
}
