import { Component } from "react";
import CreatableSelect from "react-select/creatable";
import Time, { nextTime, compareTime, formatTime, stringToTime } from "../../types/Time";

interface Props {
  minTime: Time;
  maxTime: Time;
  greaterThanMin?: boolean;
  lowerThanMax?: boolean;
  time: Time;
  disabled?: boolean;
  ariaLabel?: string;
  inModal?: boolean;

  onChange: (time: Time) => void;
}

export default class TimePicker extends Component<Props> {
  constructor(props: Props) {
    super(props);

    [
      "inputedTimeIsValid",
      "onTimeSelected"
    ].forEach(fn => this[fn] = this[fn].bind(this));
  }

  onTimeSelected({ value }, { action }): void {
    const time = action === "create-option" ? stringToTime(value) : value;

    this.props.onChange(time);
  }

  options(): any {
    const { minTime, maxTime, greaterThanMin } = this.props;

    const options = [];
    let t = greaterThanMin ? nextTime(minTime) : minTime;
    for (t; t && compareTime(t, maxTime) === -1; t = nextTime(t)) {
      options.push({
        value: t, label: formatTime(t)
      });
    }

    return options;
  }

  timeIsInRange(t: Time): boolean {
    const { minTime, maxTime, greaterThanMin, lowerThanMax } = this.props;

    const minCmpExpected = greaterThanMin ? 1 : 0;
    const maxCmpExpected = lowerThanMax ? -1 : 0;

    return compareTime(t, minTime) >= minCmpExpected && compareTime(t, maxTime) <= maxCmpExpected;
  }

  inputedTimeIsValid(value: string): boolean {
    const time = stringToTime(value);
    return time && this.timeIsInRange(time);
  }

  render(): JSX.Element {
    const { time, disabled, ariaLabel, inModal } = this.props;

    return <label className="time-picker">
      <CreatableSelect
        value={{ value: time, label: formatTime(time) }}
        options={this.options()}
        components={{ DropdownIndicator: (): JSX.Element => null, IndicatorSeparator: (): JSX.Element => null }}
        noOptionsMessage={(): string => null}
        formatCreateLabel={(value): void => value}
        isValidNewOption={this.inputedTimeIsValid}
        onChange={this.onTimeSelected}
        isDisabled={disabled}
        className="react-select"
        classNamePrefix="react-select"
        aria-label={ariaLabel}
        menuPlacement="auto"
        menuPosition={inModal ? "absolute" : "fixed"}
        styles={!inModal ? {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          menuPortal: ({ left, ...provided }, state) => ({
            ...provided
          })
        } : ""}
      />
    </label>;
  }
}
