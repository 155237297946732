import { FunctionComponent } from "react";
import NumberPicker from "../utils/NumberPicker.react";

interface Props {
  duration?: number;
  disabled?: boolean;
  defaultSlotDurationMinutes?: number;
  inModal?: boolean;

  onChange: (duration: number) => void;
}

const MAX = 120;

const SlotDurationPicker: FunctionComponent<Props> = ({
  duration,
  disabled,
  defaultSlotDurationMinutes,
  inModal,
  onChange }) => {

  return <NumberPicker
    num={duration}
    min={defaultSlotDurationMinutes}
    max={MAX}
    step={defaultSlotDurationMinutes}
    disabled={disabled}
    format={(d: number): string => `${d}min`}
    onChange={onChange}
    ariaLabel="Slot Duration"
    inModal={inModal}
  />;
};

export default SlotDurationPicker;
